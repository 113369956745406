<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Shortlist" subtitle="Events that you're interested in possibly joining but have not yet seated.">

	</app-content-head>

	<app-events-filter :filter="filter" :references="references" />

	<app-events v-on:hide="load" :loading="is.loading && is.initialised" :filter="filter" :collection="collection" :columns="columns" :pagination="pagination" :total="total" placeholder="Sorry, no events found." />

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			endpoint: 'convention/schedule/shortlist',
			live: 'convention/schedule/event',
			filterTemplate: 'events',
			references: {
				times: [],
				days: [],
				lobbies: []
			},
			columns: {
				game: 200,
				seats: 100,
				date: 200,
				experience: 100,
				duration: 100,
				location: 'auto',
				actions: 72
			}
		}

	}

}

</script>

<style scoped>

</style>